import { createTheme, Breakpoint } from "@mui/material/styles";
import { Theme, } from '@mui/material'
import { fontFamily } from "@mui/system";

declare module '@mui/material/styles' {
    interface BreakpointOverrides {

        se: true;

    }
}

export const theme: Theme = createTheme(
    {
        breakpoints: {
            values: {
                xs: 0,
                se: 400,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
        typography: {
            body1: {
                color: '#90584b',
                WebkitFontSmoothing: 'auto',
                textTransform: 'uppercase',
                letterSpacing: 2,
                lineHeight: 1.7,
                fontSize: '.7rem',
            },
            body2: {
                color: '#90584b',
                WebkitFontSmoothing: 'auto',
                lineHeight: 1.4,
                fontSize: '.9rem',
                textTransform: 'none',
                letterSpacing: 0,
            },
            
            fontFamily: 'haboro-soft, sans-serif;',
        }
    }
)