import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { ThemeProvider, Theme, } from "@mui/material/styles";
import { useEffect } from "react";
import "./App.css";
import { theme } from "./style/JiliTheme";
import { Container, Typography, Box, CssBaseline } from "@mui/material";
import LandingPage from "./pages/LandingPage";

export default function App() {

 
  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
         
            <Route index element={<LandingPage />} />

          
        </Routes>
      </BrowserRouter>
    </ThemeProvider>

  );
}
