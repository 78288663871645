import { Box, Container, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useSpring, config, animated, useTrail } from '@react-spring/web'
import React, { useEffect, useRef, useState } from 'react'
import animateScrollTo from 'animated-scroll-to'


function LandingPage() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const isLandscape = window.innerWidth > window.innerHeight && window.innerWidth < 900

    const [showBottom, setShowBottom] = useState(true)
    const [bottomY, setBottomY] = useState(0);
    const bottomRef = useRef<HTMLDivElement | null>(null)
    const [isResizing, setIsResizing] = useState(false)


    const aniImage = useSpring({
        from: { scale: 1, opacity: 0, },
        to: { scale: 1.01, opacity: 1 },
        //config: { mass: 10, tension: 280, friction: 1200, precision:.1 },
        config: config.molasses,
    })
    const [aniBottom, api] = useSpring(() => ({
        top: window.innerHeight,
        imageHeight: window.innerHeight,
        //from: { top: window.innerHeight, opacity: 1 },
        // to: { top: bottomY, opacity: 1 },
        delay: 1000,
        config: isResizing ? { duration: 0 } : config.molasses,
    }))
    const aniPinapple = useSpring({
        from: { opacity: 0, scale: .8 },
        to: { opacity: 1, scale: 1 },
        delay: 3200,
        config: config.wobbly,
    })

    useEffect(() => {
        const resize = () => {
            setIsResizing(true)
            setY()
        }
        window.addEventListener('resize', resize);
        return () => window.removeEventListener('resize', resize)
    }, [])

    useEffect(() => {
        if (!bottomRef.current) return;
        
        setTimeout(() => {
            setY()
        }, 2000)
    }, [bottomRef])

    const setY = () => {
        if (!bottomRef.current) return;
        console.log(window.innerHeight, bottomRef.current.getBoundingClientRect().height)

        setBottomY(window.innerHeight - bottomRef.current.getBoundingClientRect().height)
        setShowBottom(true)
        const h = isLandscape ? 180 : window.innerHeight - bottomRef.current.getBoundingClientRect().height
        console.log(window.innerHeight, bottomRef.current.getBoundingClientRect().height, h)
        api.start({
            top: h,
            imageHeight: isLandscape ? h : h * 1.3,
        })
    }

    const scrollTo = () => {
        animateScrollTo(window.innerHeight, { speed: 1500 });
    }
    return (
        <>
            <Box sx={{ height: '100vh' }}>

                <Box sx={{ overflow: 'hidden' }}>
                    <animated.div style={{ ...aniImage, width: '100%', height: aniBottom.imageHeight, transformOrigin: 'left center' }}>
                        <Box component="img" src='https://jili.jiliproperties.com/wp-content/uploads/2022/05/jili-hotels-HB1-scaled.jpg' sx={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            objectPosition: 'left 45%',
                        }} />
                    </animated.div>

                    <animated.div ref={bottomRef} style={{ ...aniBottom, position: isLandscape ? 'static' : 'absolute' }}>
                        <Box sx={{
                            minHeight: '35vh',
                            width: '100vw',
                            overflow:'hidden',
                            background: '#f1e0dc',
                            py: { xs: 3, md: 5 },
                            px: { xs: 1, se: 3, sm: 4, md: 8 }
                        }}>
                            <Container maxWidth={false} sx={{ position: 'relative' }} >
                                <Stack justifyContent='center' alignItems='center' >
                                    <Typography sx={{ letterSpacing: 5, fontSize: { xs: 'rem', md: '.9rem' } }}>JILI HOTELS AND RESORTS</Typography>
                                    <animated.div style={aniPinapple}>
                                        <Box component={'img'} src='/svgs/pineapple.svg'
                                            sx={{
                                                my: { xs: 1, se: 2, md: 4 },

                                                maxWidth: { xs: 22, md: 32 }
                                            }} />
                                    </animated.div>
                                </Stack>
                                <Grid
                                    container
                                    spacing={{ xs: 0, sm: 3 }}
                                    columns={{ xs: 1, sm: 12, md: 12 }}
                                    sx={{
                                        marginTop: { xs: '1vh', sm: '1vh', xl: 4 },

                                        //'& .MuiGrid-root': { paddingRight: 3 },
                                        '& .MuiTypography-root': {
                                            textTransform: 'uppercase',
                                            fontSize: { xs: '.6rem', sm: '.7rem' },
                                            letterSpacing: 2,
                                            lineHeight: 1.7,
                                            my: { xs: 1, sm: 0 },
                                            maxWidth: 360,
                                        }
                                    }}>
                                    <Grid item xs={1} sm={6} md={3}>
                                        <Typography>
                                            Jili Hotels and Resorts is a hotel operator
                                            for exclusive, ultraluxury locations.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography>
                                        Our commitment is to deliver modern and personalized guest services at beautiful, one-of-a-kind hotels. A luxurious, memorable experience embodies the uniqueness of a property and the best of its location.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1} md={3} container alignItems='flex-end' direction='column' >

                                        <Box>
                                            <Typography>
                                                <a href="tel:646 983 9507">646 983 9507</a>
                                            </Typography>
                                            <Typography>
                                                <a href="mailto:contact@Jilihotels.com">contact@Jilihotels.com</a>

                                            </Typography>
                                        </Box>


                                    </Grid>
                                    <Grid item xs={12} md={3} container sx={{ alignItems: { xs: 'flex-start', md: 'flex-end' } }} direction='column'  >

                                        <Typography sx={{ cursor: 'pointer' }} onClick={scrollTo}>
                                            TEAM @ JILI HOTELS AND RESORTS
                                        </Typography>

                                    </Grid>

                                </Grid>
                            </Container>
                        </Box>
                    </animated.div >

                </Box >


            </Box>
            <Team />

        </>
    )
}

export default LandingPage


const Team = () => {

    const [inView, setInView] = useState(false);
    const teamRef = useRef<null | HTMLDivElement>(null)
    useEffect(() => {
        const scroll = () => {
            if (!teamRef.current) return;
            let top = teamRef.current.getBoundingClientRect().top
            if (top < window.innerHeight) {
                //setInView(true)
                api.start({ opacity: 1, x:0 })
            } else {
                api.start({ opacity: 0, x:100 })
            }
        }
        window.addEventListener('scroll', scroll);
        return () => window.removeEventListener('scroll', scroll)
    })
    interface ITeam {
        name: string;
        title: string;
        link: string;
        bio: string;
    }
    const team: ITeam[] = [
        {
            name: " Ryan Kinser",
            title: "President and Founder",
            link: "https://www.linkedin.com/in/ryanrkinser/",
            bio: "Ryan is the founder of Jili, the boutique manager of ultraluxury condominium communities in New York and southeastern Florida valued at more than $2 billion. Prior to launching Jili, he directed the property-management division of acclaimed developer DDG and performed operations and management roles at the five-star Mandarin Oriental Hotel Group. Thanks to his specialized knowledge of hotel operations as well as the modern guest experience, Ryan is fusing technology with European hospitality traditions to create best-in-class service for a new generation of discerning travelers.",
        },
        {
            name: "Tom Nolan",
            title: "Operations",
            link: "https://www.linkedin.com/in/tfnnolan/",
            bio: "Since starting his career with Marriott International, Tom has ascended the ranks of hospitality executives for his ability to synthesize operations, sales, marketing, personnel, and capital-investment perspectives. Tom oversaw the largest full-service portfolio at Interstate Hotels & Resorts, while at Aramark, he directed a transformative change of ownership for a 1,000-room conference center. In addition to understanding the goals of multiple stakeholders, Tom is respected for his transparent communication style and ability to foster the growth of associates and managers.",
        },
        {
            name: "Michael Romei",
            title: "Hospitality",
            link: "https://www.linkedin.com/in/michael-romei-1306239/",
            bio: "Known as the “ambassador of hospitality,” Michael was chef concierge of The Towers of the Waldorf Astoria in New York City for 24 years, and he continues to define the cutting edge of hospitality practice through his Creating Service Magic seminars. He is the former general secretary of Les Clefs d’Or, the prestigious professional organization where he also served 10 years as a member of the international executive committee. In addition to his Waldorf Astoria tenure, Michael has held concierge leadership positions at the Boca Raton Resort & Club, Cipriani, and the Faena Hotel.          ",
        },
        {
            name: "Jacob Peck",
            title: "Food and Beverage",
            link: "https://www.linkedin.com/in/jacob-peck-0692808/",
            bio: "A graduate of Johnson & Wales University, Jake honed his culinary arts skills at the Washington, DC, restaurants PS7’s and Ardeo, after which he rose to executive chef positions at the Clinton Foundation and the Chicago Botanic Garden. He has also supported high-profile events, such as teatime for Queen Elizabeth II at the Kentucky Derby. Since transitioning to front-of-house management and strategy, Jake’s positions have included director of operations for the Milwaukee Bucks, where he spearheaded an innovative food and beverage program for the new Fiserv Forum arena.",
        }
    ];
    const [trail, api] = useTrail(team.length, () => ({ opacity: 0, x: 100 }))

    return (
        <Box sx={{ minHeight: '100vh', background: '#E9E2A1' }}>

            <Box sx={{
                minHeight: '35vh',
                width: '100vw',
                overflow:'hidden',
                //background: '#f1e0dc',
                py: { xs: 3, md: 5, lg: 7 },
                px: { xs: 1, se: 3, sm: 4, md: 8 }
            }}>
                <Stack justifyContent='space-between' sx={{ minHeight: '90vh', }}>
                    <Typography sx={{ textAlign: 'center', marginBottom: '18vh', fontSize: '.9rem' }}>TEAM</Typography>
                    <Container maxWidth={false} sx={{ position: 'relative' }} >
                        <Grid container
                            spacing={{ xs: 0, sm: 3 }}
                            columns={{ xs: 1, sm: 2, md: 4 }}
                            ref={teamRef}
                        >
                            {/*team.map((member: ITeam, index: number) => (
                                <Grid item xs={1}>
                                    <Typography sx={{ textTransform: 'uppercase', }}>{member.name}</Typography>
                                    <Typography sx={{ textTransform: 'uppercase' }}>{member.title}</Typography>
                                    <Typography variant="body2" sx={{ my: 3 }}>{member.bio}</Typography>
                                </Grid>
                            ))*/}
                            {trail.map((styles, index) => (
                                <Grid key={index} item xs={1}>
                                    <animated.div style={styles}>
                                        <Typography sx={{ textTransform: 'uppercase', }}>{team[index].name}</Typography>
                                        <Typography sx={{ textTransform: 'uppercase' }}>{team[index].title}</Typography>
                                        <Typography variant="body2" sx={{ my: 3 }}>{team[index].bio}</Typography>
                                    </animated.div>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                    <Stack justifyContent='center' alignItems='center' sx={{ marginTop: '5vh' }}>
                            <Box component={'img'} src='/svgs/pineapple.svg'
                                sx={{
                                    my: { xs: 1, se: 2, md: 4 },
                                    maxWidth: { xs: 22, md: 32 }
                                }} />

                            <Typography sx={{ letterSpacing: 5, fontSize: { xs: 'rem', md: '.9rem' } }}>JILI HOTELS AND RESORTS</Typography>
                        </Stack>
                </Stack>
            </Box>
        </Box>
    )
}